import 'core-js/stable';
import 'regenerator-runtime/runtime';
import smoothscroll from 'smoothscroll-polyfill';
import 'focus-within-polyfill';
import 'focus-visible';
import 'intersection-observer';
import { ResizeObserver as ResizeObserverPolyfill } from '@juggle/resize-observer';
import { gsap, ScrollToPlugin } from 'gsap/all';
import 'core-js/features/object/from-entries';

gsap.registerPlugin(ScrollToPlugin);
if (!window.ResizeObserver) window.ResizeObserver = ResizeObserverPolyfill;

// .remove() polyfill
((arr) => {
    arr.forEach((item) => {
        if (Object.prototype.hasOwnProperty.call(item, 'remove')) {
            return;
        }
        Object.defineProperty(item, 'remove', {
            configurable: true,
            enumerable: true,
            writable: true,
            value: function remove() {
                if (this.parentNode !== null) this.parentNode.removeChild(this);
            },
        });
    });
})([Element.prototype, CharacterData.prototype, DocumentType.prototype]);

smoothscroll.polyfill();
